import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import plLocale from '@fullcalendar/core/locales/pl';
import moment from 'moment';
import $ from 'jquery'

export function handleCalendar(configurations) {
    for (const configuration of configurations) {

        var calendarEl = document.getElementById('calendar_' + configuration.elementId);
    
        var calendar = new Calendar(calendarEl, {
            locale: plLocale,
            plugins: [ dayGridPlugin, listPlugin ],
            displayEventTime: false,
            initialView: configuration.mode == 1 ? 'listYear' : 'dayGridMonth',
            contentHeight: 'auto',
            events: function(info, successCallback, failureCallback) {
                if (configuration.mode === 1) {
                    if (info.start.year() === moment().year())
                        info.start = moment().format("YYYY-MM-DD");
                }
                $.ajax({
                    locale: 'pl',
                    url: '/api/events/' + configuration.calendarid + '/' + (configuration.mode == 1 ? 'list' : 'month') + '/' + info.start.toISOString().slice(0,10) + '/' + info.end.toISOString().slice(0,10),
                    type: 'GET',
                    success: function(doc) {
                        var events = eval(doc);
                        successCallback(events);
                    }
                });
            },
            eventClick: function(info) {
                if (info.event.extendedProps.text) {
                    $(info.el).popover({
                         title: info.event.title,
                         content: info.event.extendedProps.text,
                         trigger: 'hover',
                         html: true,
                         placement: 'top',
                         container: 'body'
                    }).popover('show');
                }
            },
            headerToolbar: {
                start: 'title',
                center: '',
                end: configuration.mode == 2 ? 'today,prev,next' : 'prev,next' 
            }            
        });

        calendar.render();
    }
}